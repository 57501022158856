<script setup lang="ts">
import Header from './components/header'
import Footer from './components/Footer'
import ToTop from './components/toTop'
import CountryTips from '~/components/country-tips/index.vue';

const route = useRoute()
</script>

<template>

  <div class="layouts-default flex flex-col min-h-[100vh]" :class="[`page-name--${String(route.name)}`]">
    <div class="bg-logo">
      <img src="@/assets/imgs/logo-s2.svg">
    </div>
    <CountryTips class="tabletl:hidden" />
    <Header />

    <main class="main">
      <slot />
    </main>

    <Footer />
    <ToTop />
  </div>
</template>

<style lang="scss" scoped>
.layouts-default {
  --show-bg-logo: block;

  background-color: #13192e;

  // 以下页面不显示左上角光圈
  &[class*='page-name--index___'] {
    --show-bg-logo: none;
  }
}

.main {
  z-index: 1;
  min-height: calc(100vh - 423px);
}

// 左上角光圈
.bg-logo {
  --size: clamp(200px, 40vw, 700px);

  position: fixed;
  top: calc(-1 * var(--size) / 14);
  left: calc(-1 * var(--size) / 5);
  z-index: 0;
  display: var(--show-bg-logo);
  width: var(--size);
  height: var(--size);
  pointer-events: none;

  img {
    position: relative;
    z-index: 1;
    display: block;
    width: inherit;
    height: inherit;
    opacity: 0.15;
  }

  &::before {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 0;
    width: 250%;
    height: 250%;
    content: '';
    background: radial-gradient(closest-side, #2b6fec, #0000);
    opacity: 0.5;
    transform: translate(-50%, -50%);
  }
}
</style>
